.header {
  border-bottom: 1px solid #dedede;
}

.navbar {
  background: transparent;
  .navbar-menu .navbar-start >,
  .navbar-menu .navbar-end > {
    & .navbar-item:hover:not(.logo),
    & .is-active {
      //box-shadow: inset 0 -3px 0 #0066cc;
    }
  }
}

.roche_logo {
  width: 58px;
  height: 30px;
}
