.geosuggest-group {
  display: flex;

  .geosuggest {
    flex: 1 1 auto;

    input {
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;
    }
  }

  button {
    height: 2.7em;
    margin-top: 0 !important;
    margin-left: -1px;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
  }
}
