@charset "utf-8";

$roche-theme-light: #f1f1f1;
$roche-theme-dark-light: #e1e1e1;
$roche-theme-light-blue: #e7f0fe;
$roche-theme-grey: #4d4d4d;
$roche-theme-grey-light: #dbdbdb;
$roche-theme-primary: #3e78bd;
$roche-theme-grey-lighter: #f3f3f3;

$black: #000;
$grey: #b1b1b3;
$white: #fff;
$orange: #ed8b00;
$yellow: #ffc72c;
$green: #19ab27;
$cyan: #00e5ef;
$cyan-light: #dee8f2;
$cyan-light-invert: findColorInvert($cyan-light);
$blue: $roche-theme-primary;
$purple: #a05eb5;
$red: #e40046;
$primary: $roche-theme-primary;
$primary-darker: darken($primary, 10%);
$navbar-height: 6.75rem;
$footer-height: 60px;
$radius: 5px;
$custom-colors: (
  'cyan-light': (
    $cyan-light,
    $cyan-light-invert,
  ),
);
$input-shadow: 0;
$input-height: 2.7em;
$column-gap: 1.5rem;
$modal-background-background-color: rgba(69, 87, 115, 0.7);
$info: $primary;
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,700&subset=latin-ext');
@import '~bulma/bulma';
@import '~bulma-checkradio/dist/css/bulma-checkradio.sass';
@import '~bulma-carousel/dist/css/bulma-carousel.sass';
@import '~react-hint/css/index.css';
@import '~react-tabs/style/react-tabs.css';
@import '~flatpickr/dist/flatpickr.css';
@import '~loaders.css/src/animations/ball-scale-multiple.scss';
@import 'ie.scss';
@import '~react-datepicker/dist/react-datepicker-cssmodules.css';
@import '~react-awesome-lightbox/build/style.css';
@import '~react-big-calendar/lib/sass/styles';

html {
  font-size: 14px;
}
body {
  font-family: 'Roboto', sans-serif;
  padding-top: 6.75rem;
}
strong,
b,
.has-text-weight-bold,
.navbar-item.is-active,
.navbar-link.is-active,
.navbar-brand {
  font-weight: bold;
}
h1,
h2,
h3,
h4,
h5,
h6,
.title {
  font-weight: normal;
}
html,
body,
#root {
  min-height: 100vh;
  white-space: pre-line;
}

@include mobile {
  .action-buttons {
    display: block;
  }
}

@include touch {
  body {
    padding-top: 0;
  }

  .ReactTable .rt-thead.-headerGroups {
    &,
    & .table-sort-header {
      width: 100% !important;
      min-width: auto !important;
      .column:last-child {
        padding-top: 0;
      }
    }
  }

  .table-filters {
    .column:not(:last-child) {
      padding-bottom: 0;
    }
  }

  .table-sort {
    .column {
      width: 100%;
    }
  }

  .button .icon:first-child:not(:last-child),
  .ReactTable .-pagination .-btn .icon:first-child:not(:last-child) {
    margin-left: 0;
  }

  #title-section {
    h1 {
      text-align: center;
      margin: 0 0 20px !important;
    }
    .tags {
      justify-content: flex-start;
    }
    .tag:not(body) {
      width: 50%;
      justify-content: flex-start;
    }
  }

  .navbar-start .navbar-dropdown .navbar-item,
  .navbar-start .navbar-link {
    text-transform: uppercase;
  }

  .landing-elements {
    .element {
      display: table-row;
      div,
      h6 {
        display: table-cell;
        vertical-align: middle;
      }
    }
  }

  .logo {
    display: none;
  }

  .room-details {
    .features {
      .column {
        &:not(:last-child) {
          padding-bottom: 0;
        }
        &:not(:first-child) {
          padding-top: 0;
          ul {
            margin-top: 0;
          }
        }
      }
    }
  }
}

.ReactTable .rt-table {
  overflow-x: auto !important;
  width: 100% !important;
}

.table {
  &.is-loading {
    position: relative;
    pointer-events: none;
    opacity: 0.5;
    &:after {
      @include loader;
      position: absolute;
      top: calc(50% - 1em);
      left: calc(50% - 1em);
      width: 2em;
      height: 2em;
      border-width: 0.25em;
    }
  }
}
.button {
  border-radius: $radius;
  height: 3em;
  padding-left: 2em;
  padding-right: 2em;
  &.is-transparent {
    border: 0;
    background-color: transparent;
  }
  &.is-light {
    &,
    &:hover,
    &.is-hovered,
    &:focus,
    &.is-focused {
      color: $primary;
    }
  }
  &.is-text {
    text-decoration: none;
    padding-left: 0.75em;
    padding-right: 0.75em;
    &:hover,
    &.is-hovered,
    &:focus,
    &.is-focused {
      box-shadow: none;
      background-color: transparent;
      text-decoration: underline;
    }
  }
  &.back-link {
    padding: 0;
    margin-right: 2em !important;
  }
  &.link-button {
    padding: 0;
    height: 2.25em;
  }
}
.pagination-list {
  li.is-current {
    a {
      @extend .pagination-link.is-current;
    }
  }
  .previous,
  .next {
    &.disabled {
      a {
        @extend .button[disabled];
      }
    }
  }
}
.is-pointer {
  cursor: pointer;
  pointer-events: auto;
}
.is-loading {
  .loader-container {
    position: fixed;
    top: 40%;
    left: 50%;
    transform: translate(-50%);
    width: 100%;
    &:before {
      content: '';
      background: #fff;
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 6669;
      top: 0;
      left: 0;
      pointer-events: none;
    }
    &:after {
      content: ' ';
      position: absolute;
      display: block;
      width: 66px;
      height: 66px;
      border-radius: 50%;
      border: 5px solid $primary;
      border-color: $primary transparent $primary transparent;
      animation: lds-dual-ring 1.2s linear infinite;
      z-index: 7000;
      top: 8vh;
      left: 50%;
      margin-left: -33px;
    }
    &.centered-loader:after {
      top: 50%;
      margin-top: -33px;
    }
  }
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
#title-section {
  border-bottom: 1px solid #dedede;
  position: relative;
  .title {
    font-size: 1.9em;
    line-height: 1.5em;
    text-overflow: ellipsis;
    width: auto;
    overflow: hidden;
    white-space: nowrap;
  }
  .subtitle {
    font-weight: normal;
  }
  .tags {
    .tag {
      background: none;
      span:not(:first-child) {
        margin-left: 0.5rem;
      }
      span.tag-subtext {
        margin-left: 0.5rem;
        & a {
          color: $link;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
    &.room-details-icon-panel {
      height: 100%;
      .tag {
        margin-bottom: 0;
      }
    }
  }
}
.navbar-brand {
  font-size: 1.75rem;
}
.profile-menu {
  .media-content {
    line-height: 24px;
  }
}
.toggle {
  &:hover:not(.react-toggle--disabled):not(.react-toggle--checked) {
    .react-toggle-track {
      background-color: $grey-light !important;
    }
  }
  .react-toggle-track {
    background-color: $roche-theme-grey-light;
  }
  &.react-toggle--focus,
  &:active:not(.react-toggle--disabled) {
    .react-toggle-thumb {
      box-shadow: none !important;
    }
  }
  .react-toggle-thumb {
    background: $white;
    border-color: $roche-theme-grey-light;
  }
  &.react-toggle--checked {
    .react-toggle-track {
      background-color: $green;
    }
  }
  & + span {
    font-size: 1rem;
    padding-left: 1rem;
    cursor: pointer;
    vertical-align: top;
    font-weight: normal;
    user-select: none;
    strong {
      vertical-align: top;
    }
  }
}
.table-filters {
  margin-bottom: 20px;
}
.ReactTable {
  border: 0;
  * {
    outline: 0 solid transparent;
  }
  .pagination-bottom {
    margin-top: 0.5em;
    .-pagination {
      .-center {
        order: 1;
        .-pageSizeOptions {
          display: none;
        }
        margin-right: 20px;
      }
      .-previous {
        order: 2;
      }
      .-next {
        order: 3;
      }
    }
  }
  .-pagination {
    box-shadow: none;
    border: 0;
    justify-content: flex-end;
    padding: 0;
    margin-bottom: 0.5em;
    .-center {
      justify-content: flex-end;
    }
    .-btn {
      @extend .button;
      @extend .button.is-light;
      padding: 0 0.75em;
      font-size: 1.35em;
      height: 2em;
      &:hover,
      &:hover:not([disabled]) {
        @extend .button.is-light:hover;
      }
    }
    .-btn[disabled] {
      @extend .button.is-light;
      background: $light;
      border: none;
    }
    .-pageInfo {
      margin: 0;
    }
    .-pageJump {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      input {
        margin: 0 0.5em;
        @extend .input;
        width: 60px;
      }
    }
    .-pageSizeOptions {
      margin: 0;
      display: flex;
      align-items: center;
      .react-select {
        min-width: 120px;
        background: $roche-theme-light !important;
        border-color: $roche-theme-light !important;
        margin-left: 1em;
      }
    }
    .-previous,
    .-next {
      flex: initial;
      margin-left: 10px;
    }
  }
  .pagination-top {
    .-pagination {
      .-center {
        text-align: left;
        .-pageInfo {
          display: none;
        }
      }
      .-previous {
        display: none;
      }
      .-next {
        display: none;
      }
    }
  }
  .rt-thead {
    &.-filters {
      // display: none;
      &,
      .rt-tr,
      .rt-th {
        border: 0;
        height: 0;
        margin: 0;
        padding: 0;
      }
      .rt-th {
        overflow: visible;
      }
    }
    &.-header {
      box-shadow: none;
    }
    & .rt-th.-sort-asc,
    & .rt-td.-sort-asc,
    & .rt-th.-sort-desc,
    & .rt-td.-sort-desc {
      color: $primary;
      box-shadow: none;
      & > div:first-child:before {
        position: relative;
        top: -2px;
        margin: 0 5px;
        display: inline-block;
        content: '';
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-bottom: 5px solid $primary;
      }
    }
    & .rt-th.-cursor-pointer:not(.-sort-desc):not(.-sort-asc) {
      & > div:first-child:before {
        opacity: 0.2;
        content: url(data:image/gif;base64,R0lGODlhFQAJAIABAAAAAAAAACH/C1hNUCBEYXRhWE1QPD94cGFja2V0IGJlZ2luPSLvu78iIGlkPSJXNU0wTXBDZWhpSHpyZVN6TlRjemtjOWQiPz4gPHg6eG1wbWV0YSB4bWxuczp4PSJhZG9iZTpuczptZXRhLyIgeDp4bXB0az0iQWRvYmUgWE1QIENvcmUgNS4wLWMwNjAgNjEuMTM0Nzc3LCAyMDEwLzAyLzEyLTE3OjMyOjAwICAgICAgICAiPiA8cmRmOlJERiB4bWxuczpyZGY9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkvMDIvMjItcmRmLXN5bnRheC1ucyMiPiA8cmRmOkRlc2NyaXB0aW9uIHJkZjphYm91dD0iIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtbG5zOnhtcD0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wLyIgeG1wTU06T3JpZ2luYWxEb2N1bWVudElEPSJ4bXAuZGlkOjAxODAxMTc0MDcyMDY4MTE4OEM2REYyN0ExMDhBNDJFIiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjdCNTAyODcwMEY4NjExRTBBMzkyQzAyM0E1RDk3RDc3IiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjdCNTAyODZGMEY4NjExRTBBMzkyQzAyM0E1RDk3RDc3IiB4bXA6Q3JlYXRvclRvb2w9IkFkb2JlIFBob3Rvc2hvcCBDUzUgTWFjaW50b3NoIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6MDE4MDExNzQwNzIwNjgxMTg4QzZERjI3QTEwOEE0MkUiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6MDE4MDExNzQwNzIwNjgxMTg4QzZERjI3QTEwOEE0MkUiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz4B//79/Pv6+fj39vX08/Lx8O/u7ezr6uno5+bl5OPi4eDf3t3c29rZ2NfW1dTT0tHQz87NzMvKycjHxsXEw8LBwL++vby7urm4t7a1tLOysbCvrq2sq6qpqKempaSjoqGgn56dnJuamZiXlpWUk5KRkI+OjYyLiomIh4aFhIOCgYB/fn18e3p5eHd2dXRzcnFwb25tbGtqaWhnZmVkY2JhYF9eXVxbWllYV1ZVVFNSUVBPTk1MS0pJSEdGRURDQkFAPz49PDs6OTg3NjU0MzIxMC8uLSwrKikoJyYlJCMiISAfHh0cGxoZGBcWFRQTEhEQDw4NDAsKCQgHBgUEAwIBAAAh+QQBAAABACwAAAAAFQAJAAACF4yPgMsJ2mJ4VDKKrd4GVz5lYPeMiVUAADs=);
      }
    }
    & .rt-th.-sort-desc,
    & .rt-td.-sort-desc {
      & > div:first-child:before {
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 5px solid $primary;
        border-bottom: 0;
      }
    }
    .-header-name {
      display: inline-block;
      width: 100%;
    }
    .rt-th {
      color: #363636;
      line-height: 1.5;
      font-weight: 700;
      text-align: left;
      border: 0;
      padding: 0.75em 0.5em;
      & > div {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      .dropdown {
        font-weight: 500;
      }
    }

    .table-sort-header {
      .columns {
        margin-left: 0;
        margin-right: 0;

        .column:first-child {
          padding-left: 0;
        }
      }
    }
  }
  .rt-table {
    overflow-y: visible;
    overflow-x: initial;
  }
  .rt-tbody {
    overflow: visible;
    .rt-td {
      padding: 0.5em 0.75em;
      vertical-align: top;
      line-height: 2.2rem;
      border: 0;
    }
    .rt-tr-group {
      .tr-grey {
        background: $roche-theme-light;
        color: $grey;
        &:hover {
          background: $roche-theme-light-blue !important;
        }
      }
      &,
      &:last-child {
        border: 0;
      }
      &:hover {
        background: $roche-theme-light-blue !important;
      }
    }
  }
  .dropdown {
    z-index: 14;
    position: absolute;
    right: 5px;
    top: -32px;
  }
}
.dropdown-item label {
  font-weight: 500;
  width: 100%;
}
.navbar-item {
  .flag-icon {
    margin-right: 10px;
    border: 1px solid $light;
    border-radius: 2px;
  }
  &.logo img {
    max-height: none;
  }
}
.react-tabs__tab {
  display: inline-block;
  border-color: $grey-light !important;
  border-width: 1px 0 1px 1px !important;
  border-style: solid;
  position: relative;
  padding: 0.75em 1.5em;
  border-radius: 0 !important;
  bottom: 0 !important;
}
.react-tabs__tab-list {
  display: inline-block;
  font-size: 1rem;
  overflow: hidden;
  white-space: nowrap;
  border-right: 1px solid;
  border-color: $grey-light !important;
}
.react-tabs__tab--selected {
  background: $grey !important;
  border-color: $grey !important;
  color: $white !important;
}
.footer {
  padding: 1.5rem;
  font-size: 0.75rem;
  letter-spacing: 2.4px;
  text-transform: uppercase;
  background: $roche-theme-grey-lighter;
  height: $footer-height;
}
.field.toggle-info-field:not(:last-child) {
  margin-bottom: 2.5rem;
}
.rt-td {
  .fa-image {
    color: $grey;
  }
}
.react-select.is-danger > div,
.date-time-picker.is-danger .flatpickr-input {
  border-color: $danger;
}
$thumb-initial-size: 100px;
.dropzone .thumb {
  margin: 10px;
}
.thumb {
  display: inline-block;
  position: relative;
  margin: 0 10px;
  line-height: 16px;
  font-size: 13px;
  color: #424242;
  background-color: #fff;
  border-radius: $radius;
  vertical-align: top;
  border: 1px solid #ddd;
  padding: 10px;
  .thumb-img {
    height: $thumb-initial-size;
    width: $thumb-initial-size;
    position: relative;
    overflow: hidden;
    background: whitesmoke;
    border-radius: 3px;
  }
  img {
    position: absolute;
    left: 50%;
    top: 50%;
    height: 100%;
    width: auto;
    max-width: none;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
  .thumb-delete {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 15;
    height: 30px;
    width: 30px;
    background: #fff;
    text-align: center;
    border-radius: $radius;
    cursor: pointer;
    line-height: 30px;
  }
  label {
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-top: 5px;
    width: $thumb-initial-size;
  }
}
.dropzone {
  user-select: none;
  width: 100%;
  background-color: #eee;
  min-height: 400px;
  border-radius: $radius;
  overflow-y: hidden;
  padding: 20px;
  outline: 2px dashed $grey-light;
  outline-offset: -22px;
  transition: all 0.25s ease-in-out;
  .info {
    position: absolute;
    font-size: 200%;
    left: 0;
    width: 100%;
    text-align: center;
    top: 40%;
    opacity: 0.25;
    padding: 0 30px;
    &.no-opacity {
      opacity: 1;
    }
  }
  &.active {
    background: $light;
    outline-color: $primary;
    color: $primary;
  }
}
$sizes: (0, 1, 2);
$positions: ('top', 'left', 'bottom', 'right');
@each $size in $sizes {
  @each $position in $positions {
    $posKey: nth($position, 1);
    .m-#{$posKey}-#{$size} {
      margin-#{$posKey}: $size + em !important;
    }
  }
}
.table-tooltip {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  & > div {
    position: absolute !important;
  }
  .react-hint {
    z-index: 21;
    &:after {
      display: none;
    }
  }
  .tooltip-box {
    .media-tabs {
      .centered-loader,
      .carousel-item {
        min-height: 240px !important;
      }
    }
    padding: 1rem;
    white-space: normal !important;
    &:empty {
      display: none;
    }
  }
  .react-tabs__tab {
    padding: 0.25em 1em;
  }
}
.flatpickr-day.selected,
.flatpickr-day.startRange,
.flatpickr-day.endRange,
.flatpickr-day.selected.inRange,
.flatpickr-day.startRange.inRange,
.flatpickr-day.endRange.inRange,
.flatpickr-day.selected:focus,
.flatpickr-day.startRange:focus,
.flatpickr-day.endRange:focus,
.flatpickr-day.selected:hover,
.flatpickr-day.startRange:hover,
.flatpickr-day.endRange:hover,
.flatpickr-day.selected.prevMonthDay,
.flatpickr-day.startRange.prevMonthDay,
.flatpickr-day.endRange.prevMonthDay,
.flatpickr-day.selected.nextMonthDay,
.flatpickr-day.startRange.nextMonthDay,
.flatpickr-day.endRange.nextMonthDay {
  background: $primary;
  border-color: $primary;
}
.flatpickr-months .flatpickr-prev-month:hover svg,
.flatpickr-months .flatpickr-next-month:hover svg {
  fill: $primary;
}
.img-broken {
  opacity: 0.25;
  display: block;
  height: auto;
  background: $grey;
  text-align: center;
}
.carousel {
  user-select: none;
  .carousel-container {
    -webkit-transform: none !important;
    -moz-transform: none !important;
    -ms-transform: none !important;
    -o-transform: none !important;
    transform: none !important;
  }
  .carousel-navigation.is-overlay {
    .carousel-nav-right,
    .carousel-nav-left {
      border-radius: 50%;
      height: 2em;
      width: 2em;
      margin: 0 1em;
      color: #3e78bd;
      background: $white;
      opacity: 1;
      &:hover {
        background: $roche-theme-light;
      }
    }
  }
}
.room-row-info {
  line-height: initial;
  .properties {
    .label-block {
      margin-bottom: 0.75em;
      .label {
        margin-top: 0;
        margin-bottom: 0.25em;
      }
      &:not(:last-child) {
        border-bottom: 1px solid $roche-theme-light;
      }
    }
  }
  .map-container {
    height: 250px !important;
  }
}
hr.thin {
  margin: 0.25rem 0;
}

.tooltip-default-colors {
  .box {
    background: $primary !important;
    color: white;
    z-index: 9999;
  }
}

.react-hint {
  .react-hint__content {
    padding: 10px;
    font-size: 0.75rem;
    line-height: 0.75rem;
    background: $primary;
    border-radius: $radius;
  }
  &.react-hint--top:after {
    border-top-color: $primary;
  }
  &.react-hint--left:after {
    border-left-color: $primary;
  }
  &.react-hint--right:after {
    border-right-color: $primary;
  }
  &.react-hint--bottom:after {
    border-bottom-color: $primary;
  }
}
.info-tooltip {
  .react-hint__content {
    background: $roche-theme-light-blue;
    color: $primary;
  }
  .react-hint--top:after {
    border-top-color: $roche-theme-light-blue;
  }
  .react-hint--left:after {
    border-left-color: $roche-theme-light-blue;
  }
  .react-hint--right:after {
    border-right-color: $roche-theme-light-blue;
  }
  .react-hint--bottom:after {
    border-bottom-color: $roche-theme-light-blue;
  }
}
.td-actions {
  overflow: visible !important;
  .button {
    padding: 0 0.5em;
    font-size: 1.25em;
    height: 1.75em;
  }
}
.media-tabs {
  .react-tabs__tab-list {
    user-select: none;
    border-radius: 40px !important;
    border: 3px solid $roche-theme-light !important;
    background: $roche-theme-light;
    overflow: hidden;
    margin: 0 auto;
    margin-bottom: 0.5em !important;
    font-size: 0.85em;
  }
  .react-tabs__tab {
    color: #3e78bd !important;
    border: 0 !important;
    padding: 0.25rem 1rem;
    line-height: 1.75rem !important;
    &.react-tabs__tab--selected {
      border-radius: 40px !important;
      background: $white !important;
    }
    &:focus {
      box-shadow: none;
    }
  }
  .react-tabs__tab-panel {
    border-radius: $radius;
    overflow: hidden;
  }
  .centered-loader {
    min-height: 400px;
    align-items: center;
    display: flex;
  }
  img {
    display: block;
    margin: auto;
    border-radius: $radius;
  }
}
.map-container {
  height: 300px;
}
.room-details {
  .map-container {
    height: 400px;
  }
}
.section {
  padding-top: 2em;
  padding-bottom: 2em;
}
.search {
  input {
    background: $roche-theme-light;
    border: 0;
    height: 2.75em;
    padding-left: 2.75em !important;
    padding-right: 2.75em !important;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  .control .icon {
    width: 2.75em;
    height: 2.75em;
  }
}
.label,
.rt-th {
  text-transform: uppercase;
  letter-spacing: 3px;
  font-weight: normal !important;
  font-size: 0.75em;
  color: $roche-theme-grey;
}
.icon-with-text {
  > span {
    vertical-align: middle;
  }
  .icon {
    margin-right: 0.5em;
  }
}
.is-checkradio[type='radio'] + label::after,
.is-checkradio[type='radio'] + label::before {
  width: 1rem;
  height: 1rem;
}
.is-checkradio[type='radio'] + label::before {
  left: 0.4em;
  top: 0.4em;
}
.is-checkradio[type='radio'] + label::after {
  top: 0.4em;
  left: 0.4em;
}
.header {
  position: fixed;
  top: 0;
  background: #fff;
  z-index: 9990;
  width: 100%;
}
.media-uploader .panel .panel-block,
body > .sort-item,
.rt-tbody .rt-tr-group {
  border: 1px solid $roche-theme-dark-light !important;
  margin-bottom: 5px;
  border-radius: $radius;
}
body > .sort-item {
  background: #fff;
}
.form-section {
  padding-bottom: 3em;
  &:not(:last-child) {
    border-bottom: 1px solid $roche-theme-dark-light;
    margin-bottom: 3em;
  }
  & + .form-buttons {
    padding-top: 0;
    border-top: 0;
  }
}
.form-buttons {
  border-top: 1px solid $roche-theme-dark-light;
  padding-top: 3em;
  margin-top: 1.5em;
}
@media screen and (max-width: 1087px) {
  .header {
    position: static;
  }
  .navbar-menu {
    box-shadow: none;
  }
  .navbar-dropdown {
    box-shadow: none;
  }
  .landing-enduser {
    .landing-skip {
      position: static !important;
      color: $white !important;
      text-align: center !important;
      margin-top: 1.5rem;
    }
  }
}
@media screen and (min-width: 1088px) {
  .navbar {
    .navbar-start {
      align-items: center;
      justify-content: center;
      > .navbar-item {
        margin-left: 2em;
        &.has-dropdown .navbar-link {
          padding-left: 1.5em;
        }
        .navbar-link {
          line-height: 1.5em;
          border-radius: 18px;
          text-transform: uppercase;
          letter-spacing: 3px;
        }
        &.item-resources {
          &.has-dropdown .navbar-link {
            background-color: $roche-theme-light-blue !important;
            min-width: 161px;
          }
          &.has-dropdown.is-hoverable:hover .navbar-link {
            background-color: $roche-theme-light-blue !important;
            border-radius: 18px 18px 0 0;
          }
          .navbar-dropdown {
            border-radius: 0 0 18px 18px;
            background-color: $roche-theme-light-blue !important;
            box-shadow: none;
          }
        }
      }
    }
    .navbar-end {
      align-items: center;
      justify-content: center;
    }
    .navbar-dropdown {
      font-size: 1em;
      border-top: 0;
      border-bottom-left-radius: 18px;
      border-bottom-right-radius: 18px;
      box-shadow: 0 4px 5px rgba(0, 0, 0, 0.1);
    }
  }
}
.landing {
  &-header {
    font-size: 1.75rem;
    margin-bottom: 1em;
  }
  .tile-container {
    .tile.is-child {
      background: $light;
      border-radius: $radius;
      transition: all 0.3s;
      text-align: center;
      padding: 1.75em;
      &.has-border {
        border: $roche-theme-dark-light 1px solid;
      }
      .subtitle {
        color: $black;
        font-size: 1.5rem;
      }
      .tile-footer {
        margin-bottom: 10px;
        font-size: 1rem;
        position: relative;
        display: inline-block;
        &:after {
          content: '→';
          position: absolute;
          right: -15px;
          opacity: 0;
          transition: all 0.3s;
        }
      }
      .field .is-checkradio[type='checkbox'] + label:before,
      .field .is-checkradio[type='checkbox'] + label:hover:before {
        border: 1px solid $grey-lighter !important;
      }
      .image {
        justify-content: center;
        display: flex;
        width: 60%;
        margin: 0 auto;
        img {
          left: 0;
          right: 0;
        }
      }
    }
    a {
      .tile.is-child:hover {
        background: $white;
        box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.2);
        .tile-footer:after {
          opacity: 1;
          right: -25px;
        }
      }
    }
  }
  .skip-landing {
    padding: 4rem !important;
    display: flex;
    align-items: center;
    background: $white;
    text-align: left !important;
    font-size: 1.5em !important;
    .toggle-label {
      padding-left: 70px;
      position: relative;
      display: block;
      .toggle {
        left: 0;
        top: 3px;
        position: absolute;
        & + span {
          padding: 0;
          font-size: 1em;
        }
      }
    }
    &.skip-landing-user {
      justify-content: center;
    }
  }
  &-search {
    padding: 1em;
    display: flex;
    justify-content: center;
    align-items: center;
    .search-label {
      margin-right: 60px;
      font-size: 1.429rem;
    }
  }
}
.header-panel {
  margin-bottom: 2em;
}
.modal-close,
.delete.x-button {
  position: absolute;
  border-radius: 0;
  background: none;
  &::before,
  &::after {
    background-color: $primary;
  }
  &::after {
    height: 100%;
  }
  &::before {
    width: 100%;
  }
  &:hover,
  &:focus {
    background: none;
  }
}
.remove-button {
  border: none;
  cursor: pointer;
  pointer-events: auto;
  display: inline-block;
  outline: none;
  vertical-align: top;
  user-select: none;
  position: absolute;
  right: 0;
  top: 0;
  height: 2.5em;
  width: 2.5em;
  background: none;
  padding: 0;
  z-index: 1;
}
.modal {
  z-index: 9998;
  .modal-content {
    padding: 15px;
    .modal-card-head,
    .modal-card-foot {
      border: 0;
      background: $white;
    }
    .modal-card-foot {
      padding: 20px 20px 40px 20px;
      justify-content: center;
    }
    .modal-card-head {
      padding: 20px;
      text-align: center;
    }
    .modal-card-body {
      padding: 20px;
    }
  }
  &.modal-media-uploader {
    .modal-card-foot {
      padding: 20px !important;
    }
    .modal-card-body {
      text-align: left !important;
    }
  }
}
.media-uploader {
  .panel {
    margin: 0;
  }
}
.required-indicator {
  margin-left: 5px;
}
.react-select {
  .rs__control {
    border-radius: $radius;
    border-color: $roche-theme-grey-light;
  }
  .rs__option {
    word-break: break-all;
  }
  .rs__menu {
    z-index: 10;
  }
  .rs__menu-list {
    padding: 0;
  }
  .rs__placeholder {
    color: lightgray;
  }
  .rs__dropdown-indicator,
  .rs__dropdown-indicator:hover {
    color: $primary;
  }
  .rs__option--is-focused {
    background-color: $roche-theme-light-blue;
  }
  .rs__option--is-selected {
    background-color: $primary;
  }
  .rs__control--is-focused {
    border-color: $primary;
    box-shadow: 0 0 0 0.125em rgba(62, 120, 189, 0.25);
  }
  .rs__single-value {
    padding-right: 10px;
  }
  &.is-danger .rs__control--is-focused {
    box-shadow: 0 0 0 0.125em rgba(228, 0, 70, 0.25);
  }
  &.as-label.rs--is-disabled {
    .rs__single-value--is-disabled {
      color: inherit;
    }
    .rs__control {
      border: 0;
      background: none;
    }
    .rs__indicators {
      display: none;
    }
  }
}
.label {
  margin-top: 0.75em;
  &:not(:last-child) {
    margin-bottom: 0.75em;
  }
}
.is-horizontal .label {
  margin: 0;
}
.input,
select {
  height: 2.7em !important;
}
.buttons .button:only-child {
  margin: 0;
}
.field.without-label {
  margin-top: 3em;
}
.field.has-addons {
  .button {
    height: 2.7em !important;
  }
}
.control {
  &.has-icons-left,
  &.has-icons-right {
    a.icon,
    button.icon {
      cursor: pointer;
      pointer-events: auto;
      * {
        pointer-events: auto;
      }
    }
    button.icon:disabled {
      cursor: not-allowed;
    }
  }
}
.label-block {
  margin-bottom: 1.5em;
  .content {
    small {
      margin-left: 10px;
      text-transform: lowercase;
    }
  }
}
.details .label-block {
  margin-bottom: 3em;
  .content {
    font-size: 1.25rem;
    word-break: break-word;

    ul {
      margin-left: 1.5em;
    }
  }
}
.fa-ul {
  list-style-type: none !important;
}
.global-loader {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: $white;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9999;
  .title {
    font-size: 3rem;
    font-weight: bold;
  }
  .loader {
    border: 0;
    animation: 0;
    width: 60px;
    height: 60px;
  }
  .ball-scale-multiple > div {
    background-color: $primary;
    left: -0;
    top: -30px;
  }
  .loader-inner {
    transform: scale(2, 2);
  }
}
.wrapper {
  min-height: calc(100vh - 60px);
}
.cursor-sort {
  cursor: move;
}
.error-page {
  min-height: calc(100vh - 156px);
  display: flex;
  &__content {
    display: flex;
    flex: 1;
    align-content: center;
    justify-content: center;
    flex-direction: column;
    &--left {
      align-items: flex-end;
    }
    &--right {
      align-items: flex-start;
      p.not-found {
        max-width: 400px;
      }
    }
    .title {
      font-size: 2.143rem;
      margin-bottom: 2.143rem;
    }
  }
  &__icon {
    width: 164px;
    height: 146px;
    margin-right: 74px;
  }
}
.repeater {
  .repeater-item {
    position: relative;
    &:not(:last-child) {
      margin-bottom: 0.75rem;
    }
  }
}
.has-icons-right {
  .button .icon:first-child:last-child {
    margin-left: 0;
  }
}
.hero.is-fullheight-view {
  min-height: calc(100vh - #{$footer-height} - 1px);
}
.home {
  background: url(images/bg-login-view.png) center no-repeat / cover;
  .title,
  .subtitle {
    margin-bottom: 3rem;
    font-weight: 300 !important;
    line-height: normal;
  }
  .title {
    font-size: 3em;
  }
  .subtitle {
    font-size: 1.75em;
  }
  .content-wrap {
    width: 50%;
    text-align: center;
    margin: 0 auto;
  }
}
.landing-enduser {
  background: $roche-theme-primary url(images/bg-landing-enduser.png) no-repeat
    center bottom / cover;
  color: $white;
  padding: 6rem 0;
  .text-carousel {
    margin-bottom: 3rem;
    font-size: 1.75rem;
    font-weight: 300;
    .text-carousel-label,
    .text-carousel-item {
      padding: 0.5rem 1.25rem;
    }
    .text-carousel-item {
      border: 1px solid rgba(255, 255, 255, 0.4);
      border-radius: 25px;
    }
  }
  h1 {
    font-weight: 300;
    font-size: 3.25rem;
  }
  .hero-body {
    padding-top: 0;
    padding-bottom: 0;
  }
  .landing-skip {
    position: absolute;
    bottom: -8rem;
    text-align: right;
    right: 0;
    color: $roche-theme-grey;
  }
  .landing-search {
    padding: 6rem 0;
    .search-component {
      max-width: 590px;
    }
  }
}
.landing-elements {
  padding: 0 1.5rem;
  .element {
    color: $white;
  }
  .element-circle {
    width: 75px;
    height: 75px;
    line-height: 75px;
    border-radius: 50%;
    color: $roche-theme-primary;
    background: $white;
    float: left;
    margin-right: 1.5rem;
    text-align: center;
    font-size: 2.25rem;
  }
  .element-text {
    font-size: 1.1rem;
    line-height: 1.75rem;
  }
}
.AdminToggle {
  border: 0;
  width: 128px;
  height: 38px;
  background-color: $success;
  border-radius: 38px;
  color: $white;
  position: relative;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  outline: none;
  &__Icon {
    font-size: 20px;
    width: 32px;
    height: 32px;
    background-color: $white;
    border-radius: 36px;
    color: $black;
    position: absolute;
    top: 3px;
    left: 3px;
    transition: transform 0.2s ease-in-out;
    transform: translateX(0);
    svg {
      height: 32px;
    }
  }
  p {
    transform: translateX(5px);
    transition: transform 0.2s ease-in-out;
  }
  &--on {
    background-color: $primary;
    .AdminToggle__Icon {
      transform: translateX(90px);
    }
    p {
      transform: translateX(-5px);
    }
  }

  &--de {
    width: 168px;
  }

  &.AdminToggle--on.AdminToggle--de {
    .AdminToggle__Icon {
      transform: translateX(130px);
    }
  }
}
.user-rooms {
  &-image {
    vertical-align: middle !important;
    margin: 3px;
    padding: 0 !important;
    max-height: 145px;
    img {
      border-radius: 3px;
    }
    .loader-container {
      z-index: 16 !important;
      &:after,
      &:before {
        z-index: 16 !important;
      }
    }
    .loading,
    .loader-container.centered-loader {
      max-height: 145px !important;
      min-height: 145px;
    }
  }
  &-name {
    padding: 26px !important;
    padding-bottom: 16px !important;
    vertical-align: middle !important;
    a {
      font-size: 1.313rem;
      display: block;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
    h5 {
      margin-bottom: 14px;
    }
    .tag {
      background: none;
      margin: 0;
      padding: 0;
      .tag-text {
        font-size: 1.188rem;
      }
      img {
        margin-right: 10px;
      }
    }
  }
  &-building {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    padding: 20px;
    position: relative;
    &:after {
      content: '';
      position: absolute;
      width: 1px;
      height: 84px;
      background-color: $roche-theme-dark-light;
      right: 0;
    }
    img {
      border-radius: 3px;
      width: 81px;
      height: 81px;
      margin-right: 20px;
    }
    .no-map {
      display: flex;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: center;
      border-radius: 3px;
      width: 81px;
      height: 81px;
      margin-right: 13px;
      background-color: $roche-theme-light;
      font-size: 1.875rem;
    }
    .building-data {
      width: 135px;
      p {
        font-size: 0.875rem;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }
  }
  &-features {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    line-height: 1.6rem !important;
    &:after {
      content: '';
      position: absolute;
      width: 1px;
      height: 100%;
      background-color: $roche-theme-dark-light;
      right: 0;
    }
    ul {
      li {
        font-size: 0.875rem;
      }
    }
  }
  &-button {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    .private_room {
      h4,
      p {
        text-align: center;
      }
      h4 {
        font-size: 1rem;
        font-weight: bold;
        line-height: 1.6rem;
      }
      p {
        font-size: 0.875rem;
        line-height: 1.4rem;
      }
    }
  }
}
.carousel-item {
  display: flex !important;
  align-items: center;
  justify-content: center;
  min-height: 400px;
}
.geosuggest {
  position: relative;
  margin-bottom: 10px;
  &__suggests {
    position: absolute;
    top: calc(100% + 6px);
    left: 0;
    right: 0;
    max-height: 25em;
    padding: 0;
    margin: 0 !important;
    overflow-x: hidden;
    overflow-y: auto;
    list-style: none !important;
    z-index: 5;
    border-radius: $radius;
    border: 1px $roche-theme-grey-light solid;
    background: $white;
    font-size: 1rem;
    &--hidden {
      max-height: 0;
      overflow: hidden;
      border-width: 0;
    }
  }
  &__item {
    padding: 10px;
    margin: 0 !important;
    &:hover,
    &:focus {
      background: $roche-theme-light-blue;
    }
  }
}
.map-selector {
  display: flex;
  height: 400px;
  position: relative;
  flex-direction: column-reverse;
  #map-selector-element {
    border-radius: $radius;
  }
}
.modal-location-selector {
  p {
    margin-bottom: 0 !important;
  }
  .button {
    margin-top: 10px;
  }
}
.User-Rooms-Table.ReactTable {
  .rt-thead.-headerGroups {
    border-bottom: 0 !important;
    background: transparent !important;
  }
  .rt-thead.-header {
    height: 0;
  }
}
.table-sort {
  z-index: 1000;
  overflow: initial !important;
  &-header {
    overflow: initial !important;
  }
  .control {
    text-transform: initial;
  }
}
.floor-order {
  right: -40px !important;
  cursor: grab !important;
}

.tooltip-freatures {
  &:not(:last-child) {
    margin-right: 5px;
    &:after {
      content: ',';
    }
  }
}

.UploadPanel {
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 9990;
  background-color: #fff;
  border-top-left-radius: 5px;

  &__show-button {
    border-top-left-radius: 5px;
    border: 1px solid #dbdbdb;
    border-bottom-width: 0;
  }

  &__uploads-list {
    max-height: 300px;
    overflow-y: auto;

    .panel-block:first-child {
      border-top-width: 0;
    }
  }

  &__upload-columns.columns.is-gapless > .column {
    padding: 0 1.2rem !important;
  }

  &__upload-progress {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__error {
    color: $red;
  }

  .panel {
    width: 600px;

    & .panel-block {
      transition: opacity 0.3s;

      .columns {
        width: 100%;
      }

      ul {
        list-style: disc inside;

        li {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      &.removing {
        opacity: 0;
      }
    }
  }
}

.react-datepicker-popper {
  z-index: 1000;
}

.maintenance-form {
  margin-bottom: 100px !important;
}

.has-default-cursor {
  cursor: default;
}

.align-self-end {
  align-self: flex-end;
}

.flex-direction-column {
  display: flex;
  flex-direction: column;
}

.bold {
  font-weight: bold;
}

.color-black {
  color: $black;
}

.active-color {
  color: $green;
}

.not_yet_active-color {
  color: $yellow;
}

.error-text {
  color: $red;
}
.error-text-bold {
  @extend .error-text;
  font-weight: bold;
}

.error-text-bold-striked {
  @extend .error-text-bold;
  text-decoration: line-through;
}

.error-tag {
  height: 22px;
}

.primary-text-bold {
  color: $blue;
  font-weight: bold;
}

.button-in-row {
  padding-top: 30px;
  padding-bottom: 15px;
}

.is-grey {
  background-color: $roche-theme-grey-lighter;
  color: $blue;
}

.bulk-room-attributes-wrapper {
  @media screen and (max-width: 1407px) {
    display: flex;
    flex-direction: column;

    .bulk-button-wrapper {
      margin: 20px 0 50px;
      order: 5;
    }

    .bulk-button-wrapper > button {
      margin-right: 10px;
    }
  }

  @media screen and (min-width: 1408px) {
    display: grid;
    grid-template-columns: 17% 10% 17% 35% 17%;
    column-gap: 1%;
    margin-bottom: 50px;

    .bulk-button-wrapper {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      justify-content: space-evenly;
    }
  }

  .field {
    margin-bottom: 0;
  }
}

.bulk-room-features-wrapper {
  @media screen and (max-width: 1407px) {
    display: flex;
    flex-direction: column;

    .bulk-button-wrapper {
      margin: 20px 0 50px;
      display: grid;
      flex-direction: row;
      align-content: end;
      justify-content: start;
      grid-template-columns: 60px 150px 150px;
      column-gap: 1%;
    }
  }

  @media screen and (min-width: 1408px) {
    display: grid;
    grid-template-columns: 75% auto;
    column-gap: 1%;
    margin-bottom: 50px;

    .bulk-button-wrapper {
      //display: flex;
      //flex-direction: row;
      //align-items: flex-end;
      //justify-content: space-evenly;
      display: grid;
      flex-direction: row;
      align-content: end;
      justify-content: start;
      grid-template-columns: 60px 1fr 1fr;
      column-gap: 2%;
    }
  }

  .field {
    margin-bottom: 0;
  }

  .features-form-wrapper {
    display: grid;
  }

  .features-list-button:active,
  .features-list-button:focus {
    border-left: 1px solid;
  }
}

.bulk-room-ownership-wrapper {
  @media screen and (max-width: 1407px) {
    display: flex;
    flex-direction: column;

    .bulk-button-wrapper {
      margin: 20px 0 50px;
      order: 5;
    }

    .bulk-button-wrapper > button {
      margin-right: 10px;
    }
  }

  @media screen and (min-width: 1408px) {
    display: grid;
    grid-template-columns: 17% 17.1% 17.1% 13.35% 17.1% 13.35%;
    column-gap: 1%;
    margin-bottom: 50px;

    .bulk-button-wrapper {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      justify-content: space-evenly;
    }
  }
}

.bulk-room-clickable_link-wrapper > div.padding-bottom {
  @media screen and (min-width: 1408px) {
    padding-bottom: 22px;
  }
}

.bulk-room-clickable_link-wrapper {
  @media screen and (max-width: 1407px) {
    display: flex;
    flex-direction: column;

    .bulk-button-wrapper {
      margin: 20px 0 50px;
      display: grid;
      flex-direction: row;
      align-content: end;
      justify-content: start;
      grid-template-columns: 60px 150px 150px 150px;
      column-gap: 1%;
    }
  }

  @media screen and (min-width: 1408px) {
    display: grid;
    grid-template-columns: 20% 40% 40%;
    column-gap: 1%;
    margin-bottom: 50px;

    .bulk-button-wrapper {
      display: grid;
      flex-direction: row;
      align-content: end;
      justify-content: start;
      grid-template-columns: 60px 1fr 1fr 1fr 25px;
      column-gap: 1%;
    }
  }
}

.grid-align-bottom {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.accordion-section-title > .button {
  direction: rtl;
  font-size: 2.2em;
  color: black !important;
}

.accordion-section-title > .button:hover,
.button:focus,
.button:active {
  text-decoration: none;
}

.accordion-section-title > .button > .icon {
  margin-left: 1rem !important;
  font-size: 14px;
  color: $blue;
}

.is-grey {
  background-color: $roche-theme-grey-lighter;
  color: $blue;
}

.grid-align-bottom {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.input-tag {
  background: white;
  display: flex;
  flex-wrap: wrap;
  padding: 2px 5px 1px;
  border-color: hsl(0, 0%, 80%);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
}

.input-tag:hover {
  border-color: hsl(0, 0%, 70%);
}

.input-tag__focused {
  border-color: #2684ff !important;
  box-shadow: 0 0 0 0.125em rgba(62, 120, 189, 0.25) !important;
}
.input-tag input {
  border: none;
  width: 100%;
}

.input-tag__tags {
  display: inline-flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  width: 100%;
}

.input-tag__container {
  align-items: center;
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  padding: 2px 4px;
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
}

.input-tag__item__wrapper {
  background-color: hsl(0, 0%, 90%);
  border-radius: 2px;
  display: flex;
  margin: 2px;
  min-width: 0;
  box-sizing: border-box;
}

.input-tag__item {
  border-radius: 2px;
  color: hsl(0, 0%, 20%);
  font-size: 85%;
  overflow: hidden;
  padding: 3px;
  padding-left: 6px;
  text-overflow: ellipsis;
  white-space: nowrap;
  box-sizing: border-box;
}

.tag__item {
  padding: 0 10px;
}

.input-tag__item-removal {
  align-items: center;
  border-radius: 2px;
  display: flex;
  padding-left: 4px;
  padding-right: 4px;
  box-sizing: border-box;

  svg {
    display: inline-block;
    fill: currentColor;
    line-height: 1;
    stroke: currentColor;
    stroke-width: 0;
  }
}

.input-tag__item-removal:hover {
  background-color: #ffbdad;
  color: #de350b;
}

.input-tag__item-removal:focus {
  outline: -webkit-focus-ring-color auto 1px;
}

.input-tag__tags__input {
  width: 100%;
  margin: 2px;
  padding-bottom: 2px;
  padding-top: 2px;
  color: hsl(0, 0%, 20%);
  box-sizing: border-box;
  font-size: 1rem;

  input:focus {
    outline: none;
  }

  input::placeholder {
    color: lightgray;
    font-size: 1rem;
    font-weight: 400;
    font-family: 'Roboto', sans-serif;
  }
}

.input-tag__tags__input-error {
  font-size: 10px;
  @extend .error-text;
}

.table-filter-select__placeholder {
  color: lightgray !important;
}

.table-filter-select__control--is-focused {
  box-shadow: 0 0 0 0.125em rgba(62, 120, 189, 0.25) !important;
}

.flex-left {
  display: flex;
  flex-direction: row;
  align-content: flex-start;
}

.flex-center {
  display: flex;
  flex-direction: row;
  align-content: center;
}

.export-admin-filter-checkbox {
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: center;
  align-items: center;
}

.features-tree {
  padding-top: 0;

  .search-wrapper {
    margin-bottom: 30px;
  }

  .category-wrapper {
    button {
      font-size: 0.85em;

      span:last-child {
        margin-left: 25px;
        color: black;
      }
    }

    button:hover,
    button:focus {
      text-decoration: none !important;
    }

    .feature-row {
      display: flex;
      flex-direction: row;
      align-content: flex-start;
      align-items: center;
      font-size: 0.85em;
      font-weight: normal;
      margin-left: 60px;
    }

    .feature-checkbox {
      margin: 0 10px;
    }
  }
}

.bulk-pending-warning-modal {
  width: 740px;
}

.feature-input {
  display: flex;
  flex-direction: row;
  align-content: flex-start;

  .features-list-button {
    height: 38px;
    border-left: 0;
    padding-left: 15px;
    padding-right: 15px;
  }

  .bulk-features {
    width: 100%;
  }
}

.table-checkbox {
  margin-left: 15px;
}

.table-masterCheckbox {
  margin-left: 17px;
}

.width-200 {
  min-width: 120px;
}
.maintenanace-table {
  color: $red;
}

.maintenanace-table .rt-thead .rt-th {
  color: $red;
}

.maintenanace-table .rt-tbody .rt-tr-group {
  border: 1px solid $red !important;
}

.rooms-maintenances-tooltip {
  margin-bottom: 7px;
}

.mgh-small {
  margin: 0 10px;
}

.add-image-modal {
  display: flex;
  flex-direction: row;
  padding-left: 20px;

  label {
    padding: 0 5px 0 10px;
    display: flex;
  }
}

.full-screen-modal {
  height: 100vh;
  width: 100%;
  overflow: hidden;
  .full-screen-body {
    height: 85vh;
  }
}

.create-calendar-event-modal {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: baseline;
}

.room-availability-toolbar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 15px;
}

.room-availability-event {
  font-family: 'Roboto', sans-serif;

  .event-element {
    font-size: 12px;
    margin-bottom: 0;
    font-weight: normal;
  }

  .title {
    font-weight: bold;
    color: white;
    font-size: 12px;
  }

  .one-row {
    max-height: 14px;
    overflow: hidden;
    margin-bottom: 0;
  }

  .two-rows {
    max-height: 28px;
    overflow: hidden;
    margin-bottom: 0;
  }

  .range {
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 1200px) {
  .event-modal {
    width: 95%;
  }

  .event-modal-header {
    max-width: 95%;
  }
}
@media screen and (min-width: 1200px) {
  .event-modal {
    width: 1000px;
  }

  .event-modal-header {
    max-width: 900px;
  }
}

.event-modal {
  .event-modal-body {
    font-weight: normal;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    .event-modal-row {
      display: flex;
      justify-content: flex-start;

      p:first-of-type {
        font-weight: bold;
        margin-right: 5px;
      }
    }
  }

  .event-modal-header {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .footer-no-margin-bottom {
    padding-bottom: 0 !important;
  }
}

.rbc-event-label {
  display: none;
}

.rbc-event:focus {
  outline: none;
}

.rbc-event.rbc-selected {
  background-color: #3174ad;
}

.rbc-label,
.rbc-button-link {
  font-size: small;
  font-weight: normal;
}
