.auditlog-prev,
.auditlog-prev a {
  border: 0 !important;

  color: red;
}

.auditlog-prev a {
  text-decoration: underline;
}

.auditlog-next,
.auditlog-next a {
  border: 0 !important;

  color: green;
}

.auditlog-next a {
  text-decoration: underline;
}

.auditlog-text {
  white-space: normal;
  line-height: 1.4rem;
}

.auditlog .rt-tr-group {
  background-color: #fff;
}
